import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { Container } from './styles';

const SkeletonCard: React.FC = () => {
  return (
    <Container>
      <Skeleton
        variant="rect"
        width="230px"
        height="230px"
        style={{ borderRadius: 13, marginBottom: 20, marginRight: 20 }}
      />
      <Skeleton variant="rect" width="230px" height="30px" />
      <Box mt="5px">
        <Skeleton variant="rect" width="100px" height="30px" />
      </Box>
    </Container>
  );
};

export default SkeletonCard;
