import { memo } from 'react';

import { Container, Box } from '../styles';

import SkeletonCard from '../../CardContent/Skeleton';

const SkeletonSlider: React.FC = () => {
  return (
    <div style={{ marginTop: '40px' }}>
      <Container>
        <Box>
          {Array.from({ length: 10 }, (_, k) => k).map(item => (
            <SkeletonCard key={item.toString()} />
          ))}
        </Box>
      </Container>
    </div>
  );
};

export default memo(SkeletonSlider);
