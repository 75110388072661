import Skeleton from '@components/core/SliderContent/Skeleton';

import { memo } from 'react';

const HomeSkeleton: React.FC = () => {
  return (
    <>
      {Array.from({ length: 2 }, (_, k) => k).map(item => (
        <Skeleton key={item.toString()} />
      ))}
    </>
  );
};

export default memo(HomeSkeleton);
